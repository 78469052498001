import { AppConfig, ChatInterface, ChatModule, ChatWorkerClient } from "@mlc-ai/web-llm";

class ChatBridge {
  private chat: ChatInterface;

  private config: AppConfig;
  private selectedModel: string;

  private chatLoaded = false;
  private requestInProgress = false;

  // We use a request chain to ensure that
  // all requests send to chat are sequentialized
  private chatRequestChain: Promise<void> = Promise.resolve();

  constructor(chat: ChatInterface) {
    this.chat = chat;
  }

  /**
   * Push a task to the execution queue.
   *
   * @param task The task to be executed;
   */
  private pushTask(task: () => Promise<void>) {
    const lastEvent = this.chatRequestChain;
    this.chatRequestChain = lastEvent.then(task);
  }

  // Event handlers
  // all event handler pushes the tasks to a queue
  // that get executed sequentially
  // the tasks previous tasks, which causes them to early stop
  // can be interrupted by chat.interruptGenerate
  async generate(callback, prompt: string) {
    if (this.requestInProgress) {
      return;
    }

    this.pushTask(async () => {
      await this.generateTask(callback, prompt);
    });
  }

  setConfig(local_id: string, model_url: string, model_lib_url: string) {
    this.config = {
      model_list: [
        {
          model_url: model_url,
          local_id: local_id,
          model_lib_url: model_lib_url,
        },
      ]
    };

    this.selectedModel = local_id;
  }

  download(callback) {
    if (this.requestInProgress) {
      console.log("Request in progress, not downloading")
      return;
    }

    this.chatLoaded = false;

    this.pushTask(async () => {
      await this.init(callback);
    });
  }

  private async onReset() {
    if (this.requestInProgress) {
      // interrupt previous generation if any
      this.chat.interruptGenerate();
    }

    // try reset after previous requests finishes
    this.pushTask(async () => {
      await this.chat.resetChat();
    });
  }


  private async init(callback) {
    if (this.chatLoaded) return;

    this.requestInProgress = true;
    callback("");

    this.chat.setInitProgressCallback((report) => {
      const progress = Math.floor(report.progress * 100);
      const time = Math.floor(report.timeElapsed);

      const text = `${report.text} (${progress}%, ${time}s)`;
      callback(text);
    });

    try {
      const startTime = Date.now();

      await this.chat.reload(this.selectedModel, undefined, this.config);
      console.log(`Chat model finished loading, model=${this.selectedModel}, time=${Date.now() - startTime}ms`);
    } catch (err) {
      callback(err.toString());

      console.log(err.stack);
      this.unload();
      this.requestInProgress = false;
      return;
    }

    this.requestInProgress = false;
    this.chatLoaded = true;
  }

  private async generateTask(callback, prompt: string) {
    await this.init(callback);
    this.requestInProgress = true;

    if (prompt == "") {
      this.requestInProgress = false;
      return;
    }

    try {
      const progressCallback = (_, msg) => {
        callback(msg);
      };

      const output = await this.chat.generate(prompt, progressCallback);
      callback(output);

      const stats = await this.chat.runtimeStatsText();
      console.log(stats);

    } catch (err) {
      callback(err);
      console.log(err.stack);
      await this.unload();
    }

    this.requestInProgress = false;
  }

  private async unload() {
    await this.chat.unload();
    console.log("Chat unloaded")

    this.chatLoaded = false;
  }
}

const useWebWorker = true;
let chat: ChatInterface;

if (useWebWorker) {
  chat = new ChatWorkerClient(new Worker(
    new URL('./worker.ts', import.meta.url),
    { type: 'module' }
  ));
} else {
  chat = new ChatModule();
}

document["chat"] = new ChatBridge(chat);
